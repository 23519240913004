import React, { Component } from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout'

class Cs extends Component {
  render() {
    return (
      <Layout showBackButton={false}>
        <div className={'container'}>
          <h1>Case Studies</h1>
          <h2>Webstanky</h2>
          <br/><h3>Firemne</h3>
          <Link to={'/nase-prace/pripadove-studie/webstranky/firemne/tatry-golf-residence-park'}>/nase-prace/pripadove-studie/webstranky/firemne/tatry-golf-residence-park</Link><br/>
          <Link to={'/nase-prace/pripadove-studie/webstranky/firemne/sangre-azul'}>/nase-prace/pripadove-studie/webstranky/firemne/sangre-azul</Link><br/>
          <Link to={'/nase-prace/pripadove-studie/webstranky/firemne/hypoteka'}>/nase-prace/pripadove-studie/webstranky/firemne/hypoteka</Link><br/>
          <br/><h3>E-shopy</h3>
          <Link to={'/nase-prace/pripadove-studie/webstranky/eshopy/wineplanet'}>/nase-prace/pripadove-studie/webstranky/eshopy/wineplanet</Link><br/>
          <Link to={'/nase-prace/pripadove-studie/webstranky/eshopy/smartwear'}>/nase-prace/pripadove-studie/webstranky/eshopy/smartwear</Link><br/>
          <Link to={'/nase-prace/pripadove-studie/webstranky/eshopy/supro'}>/nase-prace/pripadove-studie/webstranky/eshopy/supro</Link><br/>
          <br/><h3>Portaly</h3>
          <Link to={'/nase-prace/pripadove-studie/webstranky/portaly/zamestnam'}>/nase-prace/pripadove-studie/webstranky/portaly/zamestnam</Link><br/>
          <Link to={'/nase-prace/pripadove-studie/webstranky/portaly/letenky'}>/nase-prace/pripadove-studie/webstranky/portaly/letenky</Link><br/>
          <Link to={'/nase-prace/pripadove-studie/webstranky/portaly/ako-stavat'}>/nase-prace/pripadove-studie/webstranky/portaly/ako-stavat</Link><br/>
          <br/><h3>Microsite</h3>
          <Link to={'/nase-prace/pripadove-studie/webstranky/microsite/matchinx'}>/nase-prace/pripadove-studie/webstranky/microsite/matchinx</Link><br/>
          <Link to={'/nase-prace/pripadove-studie/webstranky/microsite/expandeco'}>/nase-prace/pripadove-studie/webstranky/microsite/expandeco</Link><br/>
          <Link to={'/nase-prace/pripadove-studie/webstranky/microsite/spusti-web'}>/nase-prace/pripadove-studie/webstranky/microsite/spusti-web</Link><br/>
          <br/><h3>CMR</h3>
          <h2>Aplikácie</h2>
          <Link to={'/nase-prace/pripadove-studie/aplikacie/cario'}>/nase-prace/pripadove-studie/aplikacie/cario</Link><br/>
          <Link to={'/nase-prace/pripadove-studie/aplikacie/biutimi'}>/nase-prace/pripadove-studie/aplikacie/biutimi</Link><br/>
          <Link to={'/nase-prace/pripadove-studie/aplikacie/flipwork'}>/nase-prace/pripadove-studie/aplikacie/flipwork</Link><br/>
          <h2>Grafika</h2>
          <br/><h3>Logo/Branding</h3>
          <Link to={'/nase-prace/pripadove-studie/grafika/logo-branding/inspirativni'}>/nase-prace/pripadove-studie/grafika/logo-branding/inspirativni</Link><br/>
          <Link to={'/nase-prace/pripadove-studie/grafika/logo-branding/hledej-remeselnika'}>/nase-prace/pripadove-studie/grafika/logo-branding/hledej-remeselnika</Link><br/>
          <Link to={'/nase-prace/pripadove-studie/grafika/logo-branding/openmind'}>/nase-prace/pripadove-studie/grafika/logo-branding/openmind</Link><br/>
          <br/><h3>Digital/Print</h3>
          <Link to={'/nase-prace/pripadove-studie/grafika/digital-print/hello-kitty'}>/nase-prace/pripadove-studie/grafika/digital-print/hello-kitty</Link><br/>
          <Link to={'/nase-prace/pripadove-studie/grafika/digital-print/fertilomat'}>/nase-prace/pripadove-studie/grafika/digital-print/fertilomat</Link><br/>
          <Link to={'/nase-prace/pripadove-studie/grafika/digital-print/dpb'}>/nase-prace/pripadove-studie/grafika/digital-print/dpb</Link><br/>
        </div>
      </Layout>
    )
  }
}

export default Cs